.modal-content{
  width: 90% !important;
}
.edit-contact-modal .modal-header {
  background-color: #f7f7f7;
  border-bottom: 1px solid #dee2e6;
  padding: 1rem;
}

.edit-contact-modal .modal-title {
  font-size: 2rem !important; /* Adjust the font size as needed */
  color: #333;
  margin: 0 auto;
}

.edit-contact-modal .modal-body {
  padding: 2.5rem;
  background-color: #fff;
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: calc(100vh - 210px); /* Adjust the max-height to your needs */
}

.edit-contact-modal .modal-header,
.edit-contact-modal .modal-footer {
  flex-shrink: 0; /* Ensure header and footer do not shrink */
}

.edit-contact-modal .form-group {
  margin-bottom: 1rem;
}

.edit-contact-modal .form-label {
  font-weight: 600;
  font-size: 1.6rem; /* Smaller font size for labels */
  display: block;
  margin-bottom: 0.5rem;
}

.edit-contact-modal .form-control {
  width: 100%; /* Ensure full width for inputs */
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem; /* Adjust padding to make input fields taller or shorter */
  font-size: 1.7rem;
  border-radius: 4px;
  padding: 8px;
}

.edit-contact-modal .form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.edit-contact-modal .modal-footer {
  padding: 1.3rem;
  border-top: 1px solid #dee2e6;
  background-color: #f7f7f7;
}
.edit-contact-modal .modal-footer .btn {
  border-radius: 0.25rem; /* Adjust for rounded corners */
  padding: 1rem 3.5rem; /* Adjust for padding */
  margin-right: 20px;
  font-size: 1.5rem; /* Adjust font size for readability */
  line-height: 1.5; /* Standard line-height */
  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent; /* No border visible */
  font-weight: 400; /* Normal font weight */
}

/* Primary button styles */
.edit-contact-modal .modal-footer .btn-primary {
  color: #ffffff; /* Text color */
  background-color: #007bff; /* Background color */
  border-color: #007bff; /* Border color, if needed */
}


/* Primary button hover and active styles */
.edit-contact-modal .modal-footer .btn-primary:hover,
.edit-contact-modal .modal-footer .btn-primary:focus,
.edit-contact-modal .modal-footer .btn-primary:active {
  background-color: #0056b3; /* Darker background on hover/active */
  border-color: #0056b3; /* Darker border on hover/active */
}

/* Button shadow effect on hover */
.edit-contact-modal .modal-footer .btn:hover {
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Button shadow effect on focus for accessibility */
.edit-contact-modal .modal-footer .btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Remove default outline on focus for cleaner style (optional) */
.edit-contact-modal .modal-footer .btn:focus {
  outline: none;
}

/* Align icons with text if you're using icons within buttons */
.edit-contact-modal .modal-footer .btn i {
  margin-right: 0.5rem; /* Adjust space between icon and text */
}

.edit-contact-modal .close-btn {
  color: #6c757d; /* Dark grey text */
  background-color: transparent; /* No background */
  border: none; /* Remove border */
}

.edit-contact-modal .save-btn {
  color: #fff !important; /* White text */
  background-color: #007bff !important;  /* Bootstrap primary color for the save button */
  border-color: #007bff !important; /* Border to match the background */
}

/* Hover effects for buttons */
.edit-contact-modal .close-btn:hover,
.edit-contact-modal .save-btn:hover {
  opacity: 0.85 !important; /* Slight transparency on hover */
}

/* Focus effects for buttons */
.edit-contact-modal .close-btn:focus,
.edit-contact-modal .save-btn:focus {
  box-shadow: none !important;  /* Remove the default focus outline */
}

/* Ensure buttons take full width on smaller screens */
@media (max-width: 768px) {
  .edit-contact-modal .modal-footer .btn {
    width: 100% !important;
    margin-bottom: 0.5rem !important;
  }
}
/* style.css */
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #000; /* Adjust the color as needed */
  font-size: 18px !important;
}

/* styles_profile.css */

.profile-modal-header {
  text-align: center;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 0.5rem;
}

.profile-modal-title {
  margin: 0;
  font-size: 1.25rem;
  color: #333;
  font-weight: bold;
}

.profile-modal-subtitle {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.profile-modal-body {
  text-align: center;
  padding: 2rem 1rem;
}

.profile-picture-upload-btn {
  background-color: #6c5ce7; /* Purple color for the button */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 20px; /* Rounded corners for the button */
}

.profile-picture-upload-btn:hover {
  background-color: #5a4daa; /* Darker shade for hover state */
}

.cancel-btn {
  color: #999;
  margin-top: 2rem;
  font-size: 0.875rem;
}

/* Additional styles for the modal */
.update-profile-picture-modal .modal-content {
  max-width: 400px;
  margin: auto;
  border-radius: 8px; /* Rounded corners for the modal */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for the modal */
}

.update-profile-picture-modal .modal-footer {
  border-top: none; /* Remove border top */
}

.update-profile-picture-modal .close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.5rem;
  color: #6c5ce7; /* Same color as the upload button for consistency */
}

/* If you are using a separate button or icon for cancel, style it here */
.cancel-icon {
  cursor: pointer;
  color: #6c5ce7;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
/* Add styles for the current profile picture and status message */
.current-profile-picture {
  margin-bottom: 1rem;
  text-align: center;
}

.current-profile-picture img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}
.upload-button-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  margin-top: 20px; /* Optional: Add some space above the button */
}
.upload-button {
  display: block; /* Fill the width of the label */
  width: 100%; /* Use full width */
  text-align: center; /* Center the text inside the button */
  cursor: pointer !important;
  display: inline-block !important;
  padding: 1.375rem 1.75rem !important;
  font-size: 1.3rem !important;
  line-height: 1.5 !important;
  border-radius: 1.25rem !important;
  color: #fff !important;
  background-color: #007bff !important;
  border: 1px solid #007bff !important;
  text-align: center !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out , border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.upload-button:hover {
  background-color: #0069d9 !important; /* Darker shade for hover state */
  border-color: #0062cc !important; /* Border color for hover state */
}

/* Style for the upload status message */
.upload-status {
  color: #28a745; /* Success color */
  text-align: center;
  margin-top: 1rem;
}
/* style.css */

.profile-picture-container {
  position: relative;
  width: 300px; /* Set the width as desired */
  height: 300px; /* Set the height as desired */
  margin: auto; /* Center the container if needed */
  border-radius: 50%;
  
}

.profile-picture-avatar {
  width: 20% !important;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile-picture {
  width: 20% !important;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile-picture-model {
  width: 200px !important;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin: auto; /* Center the image */
  display: block; /* Ensure it's block level for margin: auto to work */
 
}

.profile-picture-icon-model {
  position: absolute;
  bottom: 155px;
  background-color: rgba(255, 255, 255, 0.75); /* Semi-transparent white background */
  color: #ffffff; /* Icon color */
  border-radius: 50%;
  padding: 8px; /* Adjust size as needed */
  cursor: pointer;
  transform: translateY(50%); /* Center icon vertically on the edge */
  background-color: rgb(255, 255, 255);
}

.edit-icon-model {
  left: 300px !important; /* Adjust as needed */
  font-size: 45px;
  color: #007bff; /* Adjust icon color as needed */
}

.delete-icon-model-model {
  right: 319px; /* Adjust as needed */
  top: 300px;
  font-size: 45px;
  color: red;
}

.update-profile-picture-modal .modal-footer {
  border-top: 1px solid #dee2e6 !important;
  background-color: #f7f7f7 !important;
}
.update-profile-picture-modal .modal-footer .btn {
  border-radius: 0.25rem !important; 
  padding: 1rem 2.5rem; 
  margin-right: 20px !important;
  font-size: 1.3rem !important; 
  line-height: 1.5 !important; 
  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  text-align: center !important;
  vertical-align: middle !important;
  user-select: none !important;
  border: 1px solid transparent !important; 
  font-weight: 400 !important; 
}

.cropper-and-controls-container-model {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the children (cropper and controls) */
  justify-content: center;
  width: 100%; /* Adjust based on your layout */

}

.update-profile-picture-modal .cropper-container-model {
  min-height: 300px; /* Adjust based on your needs */

}

.controls-model {
  position: relative;
  bottom: -110px;
  left: 207px;
  width: 54%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}
/* Style adjustments for the slider within controls-model */
.crop-slider-model {
  flex-grow: 1; /* Allow the slider to fill the available horizontal space */
  margin: 0 10px; /* Spacing around the slider */
}
.update-profile-picture-modal-model .crop-slider-model {
  margin: 0 10px; /* Space around the slider for aesthetics */
  width: 88%;
}

.crop-button-model {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 12px;
  margin-left: 10px;
  margin-top: 123px;
  bottom: 56px;
  z-index: 10;
}


.crop-button-model:hover {
  background-color: #218838; /* Darker shade on hover */
}

/* Adjust icon size if needed */
.crop-button-model svg {
  width: 20px; /* Icon width */
  height: 20px; /* Icon height */
}

.image-preview-container-model {
  position: relative; /* For absolute positioning of icons */
  display: flex;
  justify-content: center; /* Center the image if not full width */
}

/* Add styles for your edit and delete icons here to match the design */
.profile-picture-icon {
  position: absolute;
  cursor: pointer;
  top: 50%; /* Adjust based on actual layout */
  /* Add more styling as per your design */
}

/* Style your image preview to be circular if it's not a part of the cropper */
.profile-picture {
  border-radius: 50%;
  /* Adjust width, height, and other properties as needed */
}
/* Slider container to hold the slider and give it padding from the edges of the screen */
/* Remove the default appearance for better control over the styling */
.crop-slider-model {
  margin: 0 10px; /* Adjust spacing around slider */
  flex-grow: 1; /* Allow the slider to fill the space */
  -webkit-appearance: none;
  appearance: none;
  background: transparent; /* Remove default background */
  width: 100%;
  height: 4px; /* Height of the slider track */
}

/* Webkit browsers like Chrome, Safari, and newer versions of Edge */
.crop-slider-model::-webkit-slider-runnable-track {
background: #EAECEF; /* Light grey background for the whole track */
height: 4px;
border-radius: 2px;
}

.crop-slider-model::-webkit-slider-thumb {
-webkit-appearance: none;
margin-top: -8px; /* Move the thumb up to cut off the excess track */
width: 24px;
height: 24px;
background: #007bff;
border: 2px solid white;
border-radius: 50%;
cursor: pointer;
position: relative;
z-index: 2; /* Ensure the thumb is above the pseudo-element */
}

/* Filled part of the slider to the left of the thumb */
.crop-slider-model::-webkit-slider-runnable-track::before {
content: '';
background: #aaa; /* Color of the filled part */
height: 4px;
border-radius: 2px;
position: absolute;
z-index: 1; /* Ensure this is below the thumb */
left: 0;
width: var(--slider-fill-percentage, 50%); /* Default fill percentage */
}

/* Mozilla Firefox */
.crop-slider-model::-moz-range-track {
background: #EAECEF;
height: 4px;
border-radius: 2px;
}

.crop-slider-model::-moz-range-progress {
background: #aaa; /* Color of the filled part */
height: 4px;
border-radius: 2px;
}

.crop-slider-model::-moz-range-thumb {
width: 24px;
height: 24px;
background: #007bff;
border: 2px solid white;
border-radius: 50%;
cursor: pointer;
}

/* Focus state for Webkit slider thumb */
.crop-slider-model:focus::-webkit-slider-runnable-track {
background: #EAECEF;
}

