
.section-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.p_wrap {
    white-space: pre-line;
    text-align: left;
  }

.section-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.section-34 {
    padding-top: 34px;
    padding-bottom: 34px;
}

.section-41 {
    padding-top: 41px;
    padding-bottom: 41px;
}

.section-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.section-66 {
    padding-top: 66px;
    padding-bottom: 66px;
}

.section-85 {
    padding-top: 85px;
    padding-bottom: 85px;
}

.section-98 {
    padding-top: 98px;
    padding-bottom: 98px;
}

.section-110 {
    padding-top: 110px;
    padding-bottom: 110px;
}

.section-124 {
    padding-top: 124px;
    padding-bottom: 124px;
}

.section-254 {
    padding-top: 254px;
    padding-bottom: 254px;
}

.section-350 {
    padding-top: 350px;
    padding-bottom: 350px;
}

@media (min-width: 480px) {
    .section-xs-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .section-xs-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .section-xs-34 {
        padding-top: 34px;
        padding-bottom: 34px;
    }
    .section-xs-41 {
        padding-top: 41px;
        padding-bottom: 41px;
    }
    .section-xs-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .section-xs-66 {
        padding-top: 66px;
        padding-bottom: 66px;
    }
    .section-xs-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }
    .section-xs-98 {
        padding-top: 98px;
        padding-bottom: 98px;
    }
    .section-xs-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .section-xs-124 {
        padding-top: 124px;
        padding-bottom: 124px;
    }
    .section-xs-254 {
        padding-top: 254px;
        padding-bottom: 254px;
    }
    .section-xs-350 {
        padding-top: 350px;
        padding-bottom: 350px;
    }
}

@media (min-width: 768px) {
    .section-sm-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .section-sm-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .section-sm-34 {
        padding-top: 34px;
        padding-bottom: 34px;
    }
    .section-sm-41 {
        padding-top: 41px;
        padding-bottom: 41px;
    }
    .section-sm-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .section-sm-66 {
        padding-top: 66px;
        padding-bottom: 66px;
    }
    .section-sm-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }
    .section-sm-98 {
        padding-top: 98px;
        padding-bottom: 98px;
    }
    .section-sm-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .section-sm-124 {
        padding-top: 124px;
        padding-bottom: 124px;
    }
    .section-sm-254 {
        padding-top: 254px;
        padding-bottom: 254px;
    }
    .section-sm-350 {
        padding-top: 350px;
        padding-bottom: 350px;
    }
}

@media (min-width: 992px) {
    .section-md-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .section-md-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .section-md-34 {
        padding-top: 34px;
        padding-bottom: 34px;
    }
    .section-md-41 {
        padding-top: 41px;
        padding-bottom: 41px;
    }
    .section-md-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .section-md-66 {
        padding-top: 66px;
        padding-bottom: 66px;
    }
    .section-md-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }
    .section-md-98 {
        padding-top: 98px;
        padding-bottom: 98px;
    }
    .section-md-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .section-md-124 {
        padding-top: 124px;
        padding-bottom: 124px;
    }
    .section-md-254 {
        padding-top: 254px;
        padding-bottom: 254px;
    }
    .section-md-350 {
        padding-top: 350px;
        padding-bottom: 350px;
    }
}

@media (min-width: 1200px) {
    .section-lg-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .section-lg-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .section-lg-34 {
        padding-top: 34px;
        padding-bottom: 34px;
    }
    .section-lg-41 {
        padding-top: 41px;
        padding-bottom: 41px;
    }
    .section-lg-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .section-lg-66 {
        padding-top: 66px;
        padding-bottom: 66px;
    }
    .section-lg-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }
    .section-lg-98 {
        padding-top: 98px;
        padding-bottom: 98px;
    }
    .section-lg-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .section-lg-124 {
        padding-top: 124px;
        padding-bottom: 124px;
    }
    .section-lg-254 {
        padding-top: 254px;
        padding-bottom: 254px;
    }
    .section-lg-350 {
        padding-top: 350px;
        padding-bottom: 350px;
    }
}

@media (min-width: 1800px) {
    .section-xl-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .section-xl-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .section-xl-34 {
        padding-top: 34px;
        padding-bottom: 34px;
    }
    .section-xl-41 {
        padding-top: 41px;
        padding-bottom: 41px;
    }
    .section-xl-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .section-xl-66 {
        padding-top: 66px;
        padding-bottom: 66px;
    }
    .section-xl-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }
    .section-xl-98 {
        padding-top: 98px;
        padding-bottom: 98px;
    }
    .section-xl-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .section-xl-124 {
        padding-top: 124px;
        padding-bottom: 124px;
    }
    .section-xl-254 {
        padding-top: 254px;
        padding-bottom: 254px;
    }
    .section-xl-350 {
        padding-top: 350px;
        padding-bottom: 350px;
    }
}

.section-top-0 {
    padding-top: 0;
}

.section-top-20 {
    padding-top: 20px;
}

.section-top-24 {
    padding-top: 24px;
}

.section-top-34 {
    padding-top: 34px;
}

.section-top-41 {
    padding-top: 41px;
}

.section-top-50 {
    padding-top: 50px;
}

.section-top-66 {
    padding-top: 66px;
}

.section-top-85 {
    padding-top: 85px;
}

.section-top-98 {
    padding-top: 98px;
}

.section-top-110 {
    padding-top: 110px;
}

.section-top-124 {
    padding-top: 124px;
}

.section-top-254 {
    padding-top: 254px;
}

.section-top-350 {
    padding-top: 350px;
}

@media (min-width: 480px) {
    .section-xs-top-0 {
        padding-top: 0;
    }
    .section-xs-top-20 {
        padding-top: 20px;
    }
    .section-xs-top-34 {
        padding-top: 34px;
    }
    .section-xs-top-41 {
        padding-top: 41px;
    }
    .section-xs-top-50 {
        padding-top: 50px;
    }
    .section-xs-top-66 {
        padding-top: 66px;
    }
    .section-xs-top-85 {
        padding-top: 85px;
    }
    .section-xs-top-98 {
        padding-top: 98px;
    }
    .section-xs-top-110 {
        padding-top: 110px;
    }
    .section-xs-top-124 {
        padding-top: 124px;
    }
    .section-xs-top-254 {
        padding-top: 254px;
    }
    .section-xs-top-350 {
        padding-top: 350px;
    }
}

@media (min-width: 768px) {
    .section-sm-top-0 {
        padding-top: 0;
    }
    .section-sm-top-20 {
        padding-top: 20px;
    }
    .section-sm-top-34 {
        padding-top: 34px;
    }
    .section-sm-top-41 {
        padding-top: 41px;
    }
    .section-sm-top-50 {
        padding-top: 50px;
    }
    .section-sm-top-66 {
        padding-top: 66px;
    }
    .section-sm-top-85 {
        padding-top: 85px;
    }
    .section-sm-top-98 {
        padding-top: 98px;
    }
    .section-sm-top-110 {
        padding-top: 110px;
    }
    .section-sm-top-124 {
        padding-top: 124px;
    }
    .section-sm-top-254 {
        padding-top: 254px;
    }
    .section-sm-top-350 {
        padding-top: 350px;
    }
}

@media (min-width: 992px) {
    .section-md-top-0 {
        padding-top: 0;
    }
    .section-md-top-20 {
        padding-top: 20px;
    }
    .section-md-top-34 {
        padding-top: 34px;
    }
    .section-md-top-41 {
        padding-top: 41px;
    }
    .section-md-top-50 {
        padding-top: 50px;
    }
    .section-md-top-66 {
        padding-top: 66px;
    }
    .section-md-top-85 {
        padding-top: 85px;
    }
    .section-md-top-98 {
        padding-top: 98px;
    }
    .section-md-top-110 {
        padding-top: 110px;
    }
    .section-md-top-124 {
        padding-top: 124px;
    }
    .section-md-top-254 {
        padding-top: 254px;
    }
    .section-md-top-350 {
        padding-top: 350px;
    }
}

@media (min-width: 1200px) {
    .section-lg-top-0 {
        padding-top: 0;
    }
    .section-lg-top-20 {
        padding-top: 20px;
    }
    .section-lg-top-34 {
        padding-top: 34px;
    }
    .section-lg-top-41 {
        padding-top: 41px;
    }
    .section-lg-top-50 {
        padding-top: 50px;
    }
    .section-lg-top-66 {
        padding-top: 66px;
    }
    .section-lg-top-85 {
        padding-top: 85px;
    }
    .section-lg-top-98 {
        padding-top: 98px;
    }
    .section-lg-top-110 {
        padding-top: 110px;
    }
    .section-lg-top-124 {
        padding-top: 124px;
    }
    .section-lg-top-254 {
        padding-top: 254px;
    }
    .section-lg-top-350 {
        padding-top: 350px;
    }
}

@media (min-width: 1800px) {
    .section-xl-top-0 {
        padding-top: 0;
    }
    .section-xl-top-20 {
        padding-top: 20px;
    }
    .section-xl-top-34 {
        padding-top: 34px;
    }
    .section-xl-top-41 {
        padding-top: 41px;
    }
    .section-xl-top-50 {
        padding-top: 50px;
    }
    .section-xl-top-66 {
        padding-top: 66px;
    }
    .section-xl-top-85 {
        padding-top: 85px;
    }
    .section-xl-top-98 {
        padding-top: 98px;
    }
    .section-xl-top-110 {
        padding-top: 110px;
    }
    .section-xl-top-124 {
        padding-top: 124px;
    }
    .section-xl-top-254 {
        padding-top: 254px;
    }
    .section-xl-top-350 {
        padding-top: 350px;
    }
}

.section-bottom-0 {
    padding-bottom: 0;
}

.section-bottom-20 {
    padding-bottom: 20px;
}

.section-bottom-34 {
    padding-bottom: 34px;
}

.section-bottom-41 {
    padding-bottom: 41px;
}

.section-bottom-50 {
    padding-bottom: 50px;
}

.section-bottom-66 {
    padding-bottom: 66px;
}

.section-bottom-85 {
    padding-bottom: 85px;
}

.section-bottom-98 {
    padding-bottom: 98px;
}

.section-bottom-110 {
    padding-bottom: 110px;
}

.section-bottom-124 {
    padding-bottom: 124px;
}

.section-bottom-254 {
    padding-bottom: 254px;
}

.section-bottom-350 {
    padding-bottom: 350px;
}

@media (min-width: 480px) {
    .section-xs-bottom-0 {
        padding-bottom: 0;
    }
    .section-xs-bottom-20 {
        padding-bottom: 20px;
    }
    .section-xs-bottom-34 {
        padding-bottom: 34px;
    }
    .section-xs-bottom-41 {
        padding-bottom: 41px;
    }
    .section-xs-bottom-50 {
        padding-bottom: 50px;
    }
    .section-xs-bottom-66 {
        padding-bottom: 66px;
    }
    .section-xs-bottom-85 {
        padding-bottom: 85px;
    }
    .section-xs-bottom-98 {
        padding-bottom: 98px;
    }
    .section-xs-bottom-110 {
        padding-bottom: 110px;
    }
    .section-xs-bottom-124 {
        padding-bottom: 124px;
    }
    .section-xs-bottom-254 {
        padding-bottom: 254px;
    }
    .section-xs-bottom-350 {
        padding-bottom: 350px;
    }
}

@media (min-width: 768px) {
    .section-sm-bottom-0 {
        padding-bottom: 0;
    }
    .section-sm-bottom-20 {
        padding-bottom: 20px;
    }
    .section-sm-bottom-34 {
        padding-bottom: 34px;
    }
    .section-sm-bottom-41 {
        padding-bottom: 41px;
    }
    .section-sm-bottom-50 {
        padding-bottom: 50px;
    }
    .section-sm-bottom-66 {
        padding-bottom: 66px;
    }
    .section-sm-bottom-85 {
        padding-bottom: 85px;
    }
    .section-sm-bottom-98 {
        padding-bottom: 98px;
    }
    .section-sm-bottom-110 {
        padding-bottom: 110px;
    }
    .section-sm-bottom-124 {
        padding-bottom: 124px;
    }
    .section-sm-bottom-254 {
        padding-bottom: 254px;
    }
    .section-sm-bottom-350 {
        padding-bottom: 350px;
    }
}

@media (min-width: 992px) {
    .section-md-bottom-0 {
        padding-bottom: 0;
    }
    .section-md-bottom-20 {
        padding-bottom: 20px;
    }
    .section-md-bottom-34 {
        padding-bottom: 34px;
    }
    .section-md-bottom-41 {
        padding-bottom: 41px;
    }
    .section-md-bottom-50 {
        padding-bottom: 50px;
    }
    .section-md-bottom-66 {
        padding-bottom: 66px;
    }
    .section-md-bottom-85 {
        padding-bottom: 85px;
    }
    .section-md-bottom-98 {
        padding-bottom: 98px;
    }
    .section-md-bottom-110 {
        padding-bottom: 110px;
    }
    .section-md-bottom-124 {
        padding-bottom: 124px;
    }
    .section-md-bottom-254 {
        padding-bottom: 254px;
    }
    .section-md-bottom-350 {
        padding-bottom: 350px;
    }
}

@media (min-width: 1200px) {
    .section-lg-bottom-0 {
        padding-bottom: 0;
    }
    .section-lg-bottom-20 {
        padding-bottom: 20px;
    }
    .section-lg-bottom-34 {
        padding-bottom: 34px;
    }
    .section-lg-bottom-41 {
        padding-bottom: 41px;
    }
    .section-lg-bottom-50 {
        padding-bottom: 50px;
    }
    .section-lg-bottom-66 {
        padding-bottom: 66px;
    }
    .section-lg-bottom-85 {
        padding-bottom: 85px;
    }
    .section-lg-bottom-98 {
        padding-bottom: 98px;
    }
    .section-lg-bottom-110 {
        padding-bottom: 110px;
    }
    .section-lg-bottom-124 {
        padding-bottom: 124px;
    }
    .section-lg-bottom-254 {
        padding-bottom: 254px;
    }
    .section-lg-bottom-350 {
        padding-bottom: 350px;
    }
}

@media (min-width: 1800px) {
    .section-xl-bottom-0 {
        padding-bottom: 0;
    }
    .section-xl-bottom-20 {
        padding-bottom: 20px;
    }
    .section-xl-bottom-34 {
        padding-bottom: 34px;
    }
    .section-xl-bottom-41 {
        padding-bottom: 41px;
    }
    .section-xl-bottom-50 {
        padding-bottom: 50px;
    }
    .section-xl-bottom-66 {
        padding-bottom: 66px;
    }
    .section-xl-bottom-85 {
        padding-bottom: 85px;
    }
    .section-xl-bottom-98 {
        padding-bottom: 98px;
    }
    .section-xl-bottom-110 {
        padding-bottom: 110px;
    }
    .section-xl-bottom-124 {
        padding-bottom: 124px;
    }
    .section-xl-bottom-254 {
        padding-bottom: 254px;
    }
    .section-xl-bottom-350 {
        padding-bottom: 350px;
    }
}

/** 2.2  Flex Grid System */
.shell,
.shell-wide,
.shell-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.shell,
.shell-wide {
    min-width: 300px;
    max-width: 480px;
}

@media (min-width: 768px) {
    .shell,
    .shell-wide {
        max-width: 750px;
    }
}

@media (min-width: 992px) {
    .shell,
    .shell-wide {
        max-width: 970px;
    }
}

@media (min-width: 1200px) {
    .shell,
    .shell-wide {
        max-width: 1200px;
    }
}

@media (min-width: 1800px) {
    .shell-wide {
        max-width: 1800px;
    }
}

.range {
    margin-left: -15px;
    margin-right: -15px;
}

.range > .range {
    margin-left: 0;
    margin-right: 0;
}

.range-spacer {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
}

[class*="cell-"] {
    padding-left: 15px;
    padding-right: 15px;
}

html.lt-ie-10 * + .range,
* + .range {
    margin-top: 50px;
}

html.lt-ie-10 * + [class*='cell-'],
* + [class*='cell-'],
html.lt-ie-10 * + .range-sm,
* + .range-sm {
    margin-top: 30px;
}

html.lt-ie-10 * + .range-lg,
* + .range-lg {
    margin-top: 66px;
}

html.lt-ie-10 .range-condensed,
.range-condensed {
    margin-left: 0;
    margin-right: 0;
}

html.lt-ie-10 .range-condensed > [class*='cell'],
.range-condensed > [class*='cell'] {
    padding-left: 0;
    padding-right: 0;
}

html.lt-ie-10 .range-condensed > * + [class*='cell'],
.range-condensed > * + [class*='cell'] {
    margin-top: 0;
}

html.lt-ie-10 .range-narrow,
.range-narrow {
    margin-left: -5px;
    margin-right: -5px;
}

html.lt-ie-10 .range-narrow > [class*='cell'],
.range-narrow > [class*='cell'] {
    padding-left: 5px;
    padding-right: 5px;
}

html.lt-ie-10 .range-narrow > * + [class*='cell'],
.range-narrow > * + [class*='cell'] {
    padding-left: 5px;
    padding-right: 5px;
}

@media (min-width: 480px) {
    .range {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex: 0 1 auto;
        -webkit-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .range > .range {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .range-vertical {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .range > [class*='cell'] {
        -ms-flex: 0 0 auto;
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
}

@media (min-width: 480px) {
    * + [class*='cell-xs-'] {
        margin-top: 0;
    }
    .range-xs-center {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .range-xs-left {
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .range-xs-right {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    .range-xs-justify {
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .range-xs-around {
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
    .range-xs-top {
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .range-xs {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .range-xs-reverse {
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }
    .range-xs-middle {
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .range-xs-bottom {
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
    .range-xs-baseline {
        -webkit-align-items: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }
    .cell-xs-top {
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
        align-self: flex-start;
    }
    .cell-xs-middle {
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
    }
    .cell-xs-bottom {
        -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
        align-self: flex-end;
    }
    .range > .cell-xs-preffix-0 {
        margin-left: 0%;
    }
    .range > .cell-xs-1 {
        -webkit-flex-basis: 8.33333%;
        -ms-flex-preferred-size: 8.33333%;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
    }
    .range > .cell-xs-preffix-1 {
        margin-left: 8.33333%;
    }
    .range > .cell-xs-push-1 {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }
    .range > .cell-xs-2 {
        -webkit-flex-basis: 16.66667%;
        -ms-flex-preferred-size: 16.66667%;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
    }
    .range > .cell-xs-preffix-2 {
        margin-left: 16.66667%;
    }
    .range > .cell-xs-push-2 {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }
    .range > .cell-xs-3 {
        -webkit-flex-basis: 25%;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .range > .cell-xs-preffix-3 {
        margin-left: 25%;
    }
    .range > .cell-xs-push-3 {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }
    .range > .cell-xs-4 {
        -webkit-flex-basis: 33.33333%;
        -ms-flex-preferred-size: 33.33333%;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }
    .range > .cell-xs-preffix-4 {
        margin-left: 33.33333%;
    }
    .range > .cell-xs-push-4 {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }
    .range > .cell-xs-5 {
        -webkit-flex-basis: 41.66667%;
        -ms-flex-preferred-size: 41.66667%;
        flex-basis: 41.66667%;
        max-width: 41.66667%;
    }
    .range > .cell-xs-preffix-5 {
        margin-left: 41.66667%;
    }
    .range > .cell-xs-push-5 {
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
    }
    .range > .cell-xs-6 {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .range > .cell-xs-preffix-6 {
        margin-left: 50%;
    }
    .range > .cell-xs-push-6 {
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6;
    }
    .range > .cell-xs-7 {
        -webkit-flex-basis: 58.33333%;
        -ms-flex-preferred-size: 58.33333%;
        flex-basis: 58.33333%;
        max-width: 58.33333%;
    }
    .range > .cell-xs-preffix-7 {
        margin-left: 58.33333%;
    }
    .range > .cell-xs-push-7 {
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7;
    }
    .range > .cell-xs-8 {
        -webkit-flex-basis: 66.66667%;
        -ms-flex-preferred-size: 66.66667%;
        flex-basis: 66.66667%;
        max-width: 66.66667%;
    }
    .range > .cell-xs-preffix-8 {
        margin-left: 66.66667%;
    }
    .range > .cell-xs-push-8 {
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8;
    }
    .range > .cell-xs-9 {
        -webkit-flex-basis: 75%;
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .range > .cell-xs-preffix-9 {
        margin-left: 75%;
    }
    .range > .cell-xs-push-9 {
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9;
    }
    .range > .cell-xs-10 {
        -webkit-flex-basis: 83.33333%;
        -ms-flex-preferred-size: 83.33333%;
        flex-basis: 83.33333%;
        max-width: 83.33333%;
    }
    .range > .cell-xs-preffix-10 {
        margin-left: 83.33333%;
    }
    .range > .cell-xs-push-10 {
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10;
    }
    .range > .cell-xs-11 {
        -webkit-flex-basis: 91.66667%;
        -ms-flex-preferred-size: 91.66667%;
        flex-basis: 91.66667%;
        max-width: 91.66667%;
    }
    .range > .cell-xs-preffix-11 {
        margin-left: 91.66667%;
    }
    .range > .cell-xs-push-11 {
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11;
    }
    .range > .cell-xs-12 {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .range > .cell-xs-preffix-12 {
        margin-left: 100%;
    }
    .range > .cell-xs-push-12 {
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12;
    }
    .range > .cell-xs-1-5 {
        -webkit-flex-basis: 20%;
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }
}

@media (min-width: 768px) {
    * + [class*='cell-sm-'] {
        margin-top: 0;
    }
    .range-sm-center {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .range-sm-left {
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .range-sm-right {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    .range-sm-justify {
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .range-sm-around {
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
    .range-sm-top {
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .range-sm {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .range-sm-reverse {
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }
    .range-sm-middle {
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .range-sm-bottom {
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
    .range-sm-baseline {
        -webkit-align-items: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }
    .cell-sm-top {
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
        align-self: flex-start;
    }
    .cell-sm-middle {
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
    }
    .cell-sm-bottom {
        -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
        align-self: flex-end;
    }
    .range > .cell-sm-preffix-0 {
        margin-left: 0%;
    }
    .range > .cell-sm-1 {
        -webkit-flex-basis: 8.33333%;
        -ms-flex-preferred-size: 8.33333%;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
    }
    .range > .cell-sm-preffix-1 {
        margin-left: 8.33333%;
    }
    .range > .cell-sm-push-1 {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }
    .range > .cell-sm-2 {
        -webkit-flex-basis: 16.66667%;
        -ms-flex-preferred-size: 16.66667%;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
    }
    .range > .cell-sm-preffix-2 {
        margin-left: 16.66667%;
    }
    .range > .cell-sm-push-2 {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }
    .range > .cell-sm-3 {
        -webkit-flex-basis: 25%;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .range > .cell-sm-preffix-3 {
        margin-left: 25%;
    }
    .range > .cell-sm-push-3 {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }
    .range > .cell-sm-4 {
        -webkit-flex-basis: 33.33333%;
        -ms-flex-preferred-size: 33.33333%;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }
    .range > .cell-sm-preffix-4 {
        margin-left: 33.33333%;
    }
    .range > .cell-sm-push-4 {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }
    .range > .cell-sm-5 {
        -webkit-flex-basis: 41.66667%;
        -ms-flex-preferred-size: 41.66667%;
        flex-basis: 41.66667%;
        max-width: 41.66667%;
    }
    .range > .cell-sm-preffix-5 {
        margin-left: 41.66667%;
    }
    .range > .cell-sm-push-5 {
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
    }
    .range > .cell-sm-6 {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .range > .cell-sm-preffix-6 {
        margin-left: 50%;
    }
    .range > .cell-sm-push-6 {
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6;
    }
    .range > .cell-sm-7 {
        -webkit-flex-basis: 58.33333%;
        -ms-flex-preferred-size: 58.33333%;
        flex-basis: 58.33333%;
        max-width: 58.33333%;
    }
    .range > .cell-sm-preffix-7 {
        margin-left: 58.33333%;
    }
    .range > .cell-sm-push-7 {
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7;
    }
    .range > .cell-sm-8 {
        -webkit-flex-basis: 66.66667%;
        -ms-flex-preferred-size: 66.66667%;
        flex-basis: 66.66667%;
        max-width: 66.66667%;
    }
    .range > .cell-sm-preffix-8 {
        margin-left: 66.66667%;
    }
    .range > .cell-sm-push-8 {
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8;
    }
    .range > .cell-sm-9 {
        -webkit-flex-basis: 75%;
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .range > .cell-sm-preffix-9 {
        margin-left: 75%;
    }
    .range > .cell-sm-push-9 {
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9;
    }
    .range > .cell-sm-10 {
        -webkit-flex-basis: 83.33333%;
        -ms-flex-preferred-size: 83.33333%;
        flex-basis: 83.33333%;
        max-width: 83.33333%;
    }
    .range > .cell-sm-preffix-10 {
        margin-left: 83.33333%;
    }
    .range > .cell-sm-push-10 {
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10;
    }
    .range > .cell-sm-11 {
        -webkit-flex-basis: 91.66667%;
        -ms-flex-preferred-size: 91.66667%;
        flex-basis: 91.66667%;
        max-width: 91.66667%;
    }
    .range > .cell-sm-preffix-11 {
        margin-left: 91.66667%;
    }
    .range > .cell-sm-push-11 {
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11;
    }
    .range > .cell-sm-12 {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .range > .cell-sm-preffix-12 {
        margin-left: 100%;
    }
    .range > .cell-sm-push-12 {
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12;
    }
    .range > .cell-sm-1-5 {
        -webkit-flex-basis: 20%;
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }
}

@media (min-width: 992px) {
    * + [class*='cell-md-'] {
        margin-top: 0;
    }
    .range-md-center {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .range-md-left {
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .range-md-right {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    .range-md-justify {
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .range-md-around {
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
    .range-md-top {
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .range-md {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .range-md-reverse {
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }
    .range-md-middle {
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .range-md-bottom {
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
    .range-md-baseline {
        -webkit-align-items: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }
    .cell-md-top {
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
        align-self: flex-start;
    }
    .cell-md-middle {
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
    }
    .cell-md-bottom {
        -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
        align-self: flex-end;
    }
    .range > .cell-md-preffix-0 {
        margin-left: 0%;
    }
    .range > .cell-md-1 {
        -webkit-flex-basis: 8.33333%;
        -ms-flex-preferred-size: 8.33333%;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
    }
    .range > .cell-md-preffix-1 {
        margin-left: 8.33333%;
    }
    .range > .cell-md-push-1 {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }
    .range > .cell-md-2 {
        -webkit-flex-basis: 16.66667%;
        -ms-flex-preferred-size: 16.66667%;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
    }
    .range > .cell-md-preffix-2 {
        margin-left: 16.66667%;
    }
    .range > .cell-md-push-2 {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }
    .range > .cell-md-3 {
        -webkit-flex-basis: 25%;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .range > .cell-md-preffix-3 {
        margin-left: 25%;
    }
    .range > .cell-md-push-3 {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }
    .range > .cell-md-4 {
        -webkit-flex-basis: 33.33333%;
        -ms-flex-preferred-size: 33.33333%;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }
    .range > .cell-md-preffix-4 {
        margin-left: 33.33333%;
    }
    .range > .cell-md-push-4 {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }
    .range > .cell-md-5 {
        -webkit-flex-basis: 41.66667%;
        -ms-flex-preferred-size: 41.66667%;
        flex-basis: 41.66667%;
        max-width: 41.66667%;
    }
    .range > .cell-md-preffix-5 {
        margin-left: 41.66667%;
    }
    .range > .cell-md-push-5 {
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
    }
    .range > .cell-md-6 {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .range > .cell-md-preffix-6 {
        margin-left: 50%;
    }
    .range > .cell-md-push-6 {
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6;
    }
    .range > .cell-md-7 {
        -webkit-flex-basis: 58.33333%;
        -ms-flex-preferred-size: 58.33333%;
        flex-basis: 58.33333%;
        max-width: 58.33333%;
    }
    .range > .cell-md-preffix-7 {
        margin-left: 58.33333%;
    }
    .range > .cell-md-push-7 {
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7;
    }
    .range > .cell-md-8 {
        -webkit-flex-basis: 66.66667%;
        -ms-flex-preferred-size: 66.66667%;
        flex-basis: 66.66667%;
        max-width: 66.66667%;
    }
    .range > .cell-md-preffix-8 {
        margin-left: 66.66667%;
    }
    .range > .cell-md-push-8 {
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8;
    }
    .range > .cell-md-9 {
        -webkit-flex-basis: 75%;
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .range > .cell-md-preffix-9 {
        margin-left: 75%;
    }
    .range > .cell-md-push-9 {
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9;
    }
    .range > .cell-md-10 {
        -webkit-flex-basis: 83.33333%;
        -ms-flex-preferred-size: 83.33333%;
        flex-basis: 83.33333%;
        max-width: 83.33333%;
    }
    .range > .cell-md-preffix-10 {
        margin-left: 83.33333%;
    }
    .range > .cell-md-push-10 {
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10;
    }
    .range > .cell-md-11 {
        -webkit-flex-basis: 91.66667%;
        -ms-flex-preferred-size: 91.66667%;
        flex-basis: 91.66667%;
        max-width: 91.66667%;
    }
    .range > .cell-md-preffix-11 {
        margin-left: 91.66667%;
    }
    .range > .cell-md-push-11 {
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11;
    }
    .range > .cell-md-12 {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .range > .cell-md-preffix-12 {
        margin-left: 100%;
    }
    .range > .cell-md-push-12 {
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12;
    }
    .range > .cell-md-1-5 {
        -webkit-flex-basis: 20%;
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }
}

@media (min-width: 1200px) {
    * + [class*='cell-lg-'] {
        margin-top: 0;
    }
    .range-lg-center {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .range-lg-left {
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .range-lg-right {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    .range-lg-justify {
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .range-lg-around {
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
    .range-lg-top {
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .range-lg {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .range-lg-reverse {
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }
    .range-lg-middle {
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .range-lg-bottom {
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
    .range-lg-baseline {
        -webkit-align-items: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }
    .cell-lg-top {
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
        align-self: flex-start;
    }
    .cell-lg-middle {
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
    }
    .cell-lg-bottom {
        -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
        align-self: flex-end;
    }
    .range > .cell-lg-preffix-0 {
        margin-left: 0%;
    }
    .range > .cell-lg-1 {
        -webkit-flex-basis: 8.33333%;
        -ms-flex-preferred-size: 8.33333%;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
    }
    .range > .cell-lg-preffix-1 {
        margin-left: 8.33333%;
    }
    .range > .cell-lg-push-1 {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }
    .range > .cell-lg-2 {
        -webkit-flex-basis: 16.66667%;
        -ms-flex-preferred-size: 16.66667%;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
    }
    .range > .cell-lg-preffix-2 {
        margin-left: 16.66667%;
    }
    .range > .cell-lg-push-2 {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }
    .range > .cell-lg-3 {
        -webkit-flex-basis: 25%;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .range > .cell-lg-preffix-3 {
        margin-left: 25%;
    }
    .range > .cell-lg-push-3 {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }
    .range > .cell-lg-4 {
        -webkit-flex-basis: 33.33333%;
        -ms-flex-preferred-size: 33.33333%;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }
    .range > .cell-lg-preffix-4 {
        margin-left: 33.33333%;
    }
    .range > .cell-lg-push-4 {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }
    .range > .cell-lg-5 {
        -webkit-flex-basis: 41.66667%;
        -ms-flex-preferred-size: 41.66667%;
        flex-basis: 41.66667%;
        max-width: 41.66667%;
    }
    .range > .cell-lg-preffix-5 {
        margin-left: 41.66667%;
    }
    .range > .cell-lg-push-5 {
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
    }
    .range > .cell-lg-6 {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .range > .cell-lg-preffix-6 {
        margin-left: 50%;
    }
    .range > .cell-lg-push-6 {
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6;
    }
    .range > .cell-lg-7 {
        -webkit-flex-basis: 58.33333%;
        -ms-flex-preferred-size: 58.33333%;
        flex-basis: 58.33333%;
        max-width: 58.33333%;
    }
    .range > .cell-lg-preffix-7 {
        margin-left: 58.33333%;
    }
    .range > .cell-lg-push-7 {
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7;
    }
    .range > .cell-lg-8 {
        -webkit-flex-basis: 66.66667%;
        -ms-flex-preferred-size: 66.66667%;
        flex-basis: 66.66667%;
        max-width: 66.66667%;
    }
    .range > .cell-lg-preffix-8 {
        margin-left: 66.66667%;
    }
    .range > .cell-lg-push-8 {
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8;
    }
    .range > .cell-lg-9 {
        -webkit-flex-basis: 75%;
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .range > .cell-lg-preffix-9 {
        margin-left: 75%;
    }
    .range > .cell-lg-push-9 {
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9;
    }
    .range > .cell-lg-10 {
        -webkit-flex-basis: 83.33333%;
        -ms-flex-preferred-size: 83.33333%;
        flex-basis: 83.33333%;
        max-width: 83.33333%;
    }
    .range > .cell-lg-preffix-10 {
        margin-left: 83.33333%;
    }
    .range > .cell-lg-push-10 {
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10;
    }
    .range > .cell-lg-11 {
        -webkit-flex-basis: 91.66667%;
        -ms-flex-preferred-size: 91.66667%;
        flex-basis: 91.66667%;
        max-width: 91.66667%;
    }
    .range > .cell-lg-preffix-11 {
        margin-left: 91.66667%;
    }
    .range > .cell-lg-push-11 {
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11;
    }
    .range > .cell-lg-12 {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .range > .cell-lg-preffix-12 {
        margin-left: 100%;
    }
    .range > .cell-lg-push-12 {
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12;
    }
    .range > .cell-lg-1-5 {
        -webkit-flex-basis: 20%;
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }
}

@media (min-width: 1800px) {
    * + [class*='cell-xl-'] {
        margin-top: 0;
    }
    .range-xl-center {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .range-xl-left {
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .range-xl-right {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    .range-xl-justify {
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .range-xl-around {
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
    .range-xl-top {
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .range-xl {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .range-xl-reverse {
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }
    .range-xl-middle {
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .range-xl-bottom {
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
    .range-xl-baseline {
        -webkit-align-items: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }
    .cell-xl-top {
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
        align-self: flex-start;
    }
    .cell-xl-middle {
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
    }
    .cell-xl-bottom {
        -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
        align-self: flex-end;
    }
    .range > .cell-xl-preffix-0 {
        margin-left: 0%;
    }
    .range > .cell-xl-1 {
        -webkit-flex-basis: 8.33333%;
        -ms-flex-preferred-size: 8.33333%;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
    }
    .range > .cell-xl-preffix-1 {
        margin-left: 8.33333%;
    }
    .range > .cell-xl-push-1 {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }
    .range > .cell-xl-2 {
        -webkit-flex-basis: 16.66667%;
        -ms-flex-preferred-size: 16.66667%;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
    }
    .range > .cell-xl-preffix-2 {
        margin-left: 16.66667%;
    }
    .range > .cell-xl-push-2 {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }
    .range > .cell-xl-3 {
        -webkit-flex-basis: 25%;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .range > .cell-xl-preffix-3 {
        margin-left: 25%;
    }
    .range > .cell-xl-push-3 {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }
    .range > .cell-xl-4 {
        -webkit-flex-basis: 33.33333%;
        -ms-flex-preferred-size: 33.33333%;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }
    .range > .cell-xl-preffix-4 {
        margin-left: 33.33333%;
    }
    .range > .cell-xl-push-4 {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }
    .range > .cell-xl-5 {
        -webkit-flex-basis: 41.66667%;
        -ms-flex-preferred-size: 41.66667%;
        flex-basis: 41.66667%;
        max-width: 41.66667%;
    }
    .range > .cell-xl-preffix-5 {
        margin-left: 41.66667%;
    }
    .range > .cell-xl-push-5 {
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
    }
    .range > .cell-xl-6 {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .range > .cell-xl-preffix-6 {
        margin-left: 50%;
    }
    .range > .cell-xl-push-6 {
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6;
    }
    .range > .cell-xl-7 {
        -webkit-flex-basis: 58.33333%;
        -ms-flex-preferred-size: 58.33333%;
        flex-basis: 58.33333%;
        max-width: 58.33333%;
    }
    .range > .cell-xl-preffix-7 {
        margin-left: 58.33333%;
    }
    .range > .cell-xl-push-7 {
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7;
    }
    .range > .cell-xl-8 {
        -webkit-flex-basis: 66.66667%;
        -ms-flex-preferred-size: 66.66667%;
        flex-basis: 66.66667%;
        max-width: 66.66667%;
    }
    .range > .cell-xl-preffix-8 {
        margin-left: 66.66667%;
    }
    .range > .cell-xl-push-8 {
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8;
    }
    .range > .cell-xl-9 {
        -webkit-flex-basis: 75%;
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .range > .cell-xl-preffix-9 {
        margin-left: 75%;
    }
    .range > .cell-xl-push-9 {
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9;
    }
    .range > .cell-xl-10 {
        -webkit-flex-basis: 83.33333%;
        -ms-flex-preferred-size: 83.33333%;
        flex-basis: 83.33333%;
        max-width: 83.33333%;
    }
    .range > .cell-xl-preffix-10 {
        margin-left: 83.33333%;
    }
    .range > .cell-xl-push-10 {
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10;
    }
    .range > .cell-xl-11 {
        -webkit-flex-basis: 91.66667%;
        -ms-flex-preferred-size: 91.66667%;
        flex-basis: 91.66667%;
        max-width: 91.66667%;
    }
    .range > .cell-xl-preffix-11 {
        margin-left: 91.66667%;
    }
    .range > .cell-xl-push-11 {
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11;
    }
    .range > .cell-xl-12 {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .range > .cell-xl-preffix-12 {
        margin-left: 100%;
    }
    .range > .cell-xl-push-12 {
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12;
    }
    .range > .cell-xl-1-5 {
        -webkit-flex-basis: 20%;
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }
}

html.lt-ie-10 .range > .cell-xs-1 {
    margin-left: auto;
    margin-right: auto;
    max-width: 8.33333%;
}

html.lt-ie-10 .range > .cell-xs-2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 16.66667%;
}

html.lt-ie-10 .range > .cell-xs-3 {
    margin-left: auto;
    margin-right: auto;
    max-width: 25%;
}

html.lt-ie-10 .range > .cell-xs-4 {
    margin-left: auto;
    margin-right: auto;
    max-width: 33.33333%;
}

html.lt-ie-10 .range > .cell-xs-5 {
    margin-left: auto;
    margin-right: auto;
    max-width: 41.66667%;
}

html.lt-ie-10 .range > .cell-xs-6 {
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
}

html.lt-ie-10 .range > .cell-xs-7 {
    margin-left: auto;
    margin-right: auto;
    max-width: 58.33333%;
}

html.lt-ie-10 .range > .cell-xs-8 {
    margin-left: auto;
    margin-right: auto;
    max-width: 66.66667%;
}

html.lt-ie-10 .range > .cell-xs-9 {
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
}

html.lt-ie-10 .range > .cell-xs-10 {
    margin-left: auto;
    margin-right: auto;
    max-width: 83.33333%;
}

html.lt-ie-10 .range > .cell-xs-11 {
    margin-left: auto;
    margin-right: auto;
    max-width: 91.66667%;
}

html.lt-ie-10 .range > .cell-xs-12 {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

html.lt-ie-10 .range > .cell-xs-1-5 {
    margin-left: auto;
    margin-right: auto;
    max-width: 20%;
}

html.lt-ie-10 .range > .cell-sm-1 {
    margin-left: auto;
    margin-right: auto;
    max-width: 8.33333%;
}

html.lt-ie-10 .range > .cell-sm-2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 16.66667%;
}

html.lt-ie-10 .range > .cell-sm-3 {
    margin-left: auto;
    margin-right: auto;
    max-width: 25%;
}

html.lt-ie-10 .range > .cell-sm-4 {
    margin-left: auto;
    margin-right: auto;
    max-width: 33.33333%;
}

html.lt-ie-10 .range > .cell-sm-5 {
    margin-left: auto;
    margin-right: auto;
    max-width: 41.66667%;
}

html.lt-ie-10 .range > .cell-sm-6 {
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
}

html.lt-ie-10 .range > .cell-sm-7 {
    margin-left: auto;
    margin-right: auto;
    max-width: 58.33333%;
}

html.lt-ie-10 .range > .cell-sm-8 {
    margin-left: auto;
    margin-right: auto;
    max-width: 66.66667%;
}

html.lt-ie-10 .range > .cell-sm-9 {
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
}

html.lt-ie-10 .range > .cell-sm-10 {
    margin-left: auto;
    margin-right: auto;
    max-width: 83.33333%;
}

html.lt-ie-10 .range > .cell-sm-11 {
    margin-left: auto;
    margin-right: auto;
    max-width: 91.66667%;
}

html.lt-ie-10 .range > .cell-sm-12 {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

html.lt-ie-10 .range > .cell-sm-1-5 {
    margin-left: auto;
    margin-right: auto;
    max-width: 20%;
}

html.lt-ie-10 .range > .cell-md-1 {
    margin-left: auto;
    margin-right: auto;
    max-width: 8.33333%;
}

html.lt-ie-10 .range > .cell-md-2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 16.66667%;
}

html.lt-ie-10 .range > .cell-md-3 {
    margin-left: auto;
    margin-right: auto;
    max-width: 25%;
}

html.lt-ie-10 .range > .cell-md-4 {
    margin-left: auto;
    margin-right: auto;
    max-width: 33.33333%;
}

html.lt-ie-10 .range > .cell-md-5 {
    margin-left: auto;
    margin-right: auto;
    max-width: 41.66667%;
}

html.lt-ie-10 .range > .cell-md-6 {
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
}

html.lt-ie-10 .range > .cell-md-7 {
    margin-left: auto;
    margin-right: auto;
    max-width: 58.33333%;
}

html.lt-ie-10 .range > .cell-md-8 {
    margin-left: auto;
    margin-right: auto;
    max-width: 66.66667%;
}

html.lt-ie-10 .range > .cell-md-9 {
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
}

html.lt-ie-10 .range > .cell-md-10 {
    margin-left: auto;
    margin-right: auto;
    max-width: 83.33333%;
}

html.lt-ie-10 .range > .cell-md-11 {
    margin-left: auto;
    margin-right: auto;
    max-width: 91.66667%;
}

html.lt-ie-10 .range > .cell-md-12 {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

html.lt-ie-10 .range > .cell-md-1-5 {
    margin-left: auto;
    margin-right: auto;
    max-width: 20%;
}

html.lt-ie-10 .range > .cell-lg-1 {
    margin-left: auto;
    margin-right: auto;
    max-width: 8.33333%;
}

html.lt-ie-10 .range > .cell-lg-2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 16.66667%;
}

html.lt-ie-10 .range > .cell-lg-3 {
    margin-left: auto;
    margin-right: auto;
    max-width: 25%;
}

html.lt-ie-10 .range > .cell-lg-4 {
    margin-left: auto;
    margin-right: auto;
    max-width: 33.33333%;
}

html.lt-ie-10 .range > .cell-lg-5 {
    margin-left: auto;
    margin-right: auto;
    max-width: 41.66667%;
}

html.lt-ie-10 .range > .cell-lg-6 {
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
}

html.lt-ie-10 .range > .cell-lg-7 {
    margin-left: auto;
    margin-right: auto;
    max-width: 58.33333%;
}

html.lt-ie-10 .range > .cell-lg-8 {
    margin-left: auto;
    margin-right: auto;
    max-width: 66.66667%;
}

html.lt-ie-10 .range > .cell-lg-9 {
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
}

html.lt-ie-10 .range > .cell-lg-10 {
    margin-left: auto;
    margin-right: auto;
    max-width: 83.33333%;
}

html.lt-ie-10 .range > .cell-lg-11 {
    margin-left: auto;
    margin-right: auto;
    max-width: 91.66667%;
}

html.lt-ie-10 .range > .cell-lg-12 {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

html.lt-ie-10 .range > .cell-lg-1-5 {
    margin-left: auto;
    margin-right: auto;
    max-width: 20%;
}

html.lt-ie-10 .range > [class*="cell-xs-preffix-"],
html.lt-ie-10 .range > [class*="cell-sm-preffix-"],
html.lt-ie-10 .range > [class*="cell-md-preffix-"],
html.lt-ie-10 .range > [class*="cell-lg-preffix-"] {
    margin-left: auto;
}

/** 2.20 Text Styling System */
.text-italic {
    font-style: italic;
}

.text-normal {
    font-style: normal;
}

.text-underline {
    text-decoration: underline;
}

.text-strike {
    text-decoration: line-through;
}

.text-thin {
    font-weight: 100;
}

.text-light {
    font-weight: 300;
}

.text-regular {
    font-weight: 400;
}

.text-medium {
    font-weight: 500;
}

.text-sbold {
    font-weight: 600;
}

.text-bold, .page strong {
    font-weight: 700;
}

.text-ubold {
    font-weight: 900;
}

.text-spacing-0 {
    letter-spacing: 0;
}

.text-spacing-20 {
    letter-spacing: 0.02em;
}

.text-spacing-40 {
    letter-spacing: 0.04em;
}

.text-spacing-60 {
    letter-spacing: 0.06em;
}

.text-spacing-120 {
    letter-spacing: 0.12em;
}

    .text-mantis {
        color: #42B574;
    }


    .text-white {
        color: white;
    }

.hide {
    display: none;
}

.show {
    display: block;
}

.card.card-blog.h-95-infoSteps {
    height: 100%;
}

.hidden {
    display: none;
  }


.font-24 {
    font-size: 24px;
}

.font-20 {
    font-size: 20px;
}


#button-CTA-to-register {
    border-radius: 50%;
    height: 160px;
    width: 160px;
    font-size: 22px;
    font-weight: 600;
}

/** 2.41 Offsets */
.inset-left-0 {
	padding-left: 0;
}

.inset-left-10 {
	padding-left: 10px;
}

.inset-left-20 {
	padding-left: 20px;
}

.inset-left-30 {
	padding-left: 30px;
}

.inset-left-50 {
	padding-left: 50px;
}

.inset-left-80 {
	padding-left: 80px;
}

html .page .inset-left-93 {
	padding-left: 93px;
}

html .page .inset-left-100 {
	padding-left: 100px;
}

html .page .inset-left-115 {
	padding-left: 115px;
}

@media (min-width: 480px) {
	html .page .inset-xs-left-0 {
		padding-left: 0;
	}
	html .page .inset-xs-left-10 {
		padding-left: 10px;
	}
	html .page .inset-xs-left-20 {
		padding-left: 20px;
	}
	html .page .inset-xs-left-30 {
		padding-left: 30px;
	}
	html .page .inset-xs-left-50 {
		padding-left: 50px;
	}
	html .page .inset-xs-left-80 {
		padding-left: 80px;
	}
	html .page .inset-xs-left-93 {
		padding-left: 93px;
	}
	html .page .inset-xs-left-100 {
		padding-left: 100px;
	}
	html .page .inset-xs-left-115 {
		padding-left: 115px;
	}
}

@media (min-width: 768px) {
	html .page .inset-sm-left-0 {
		padding-left: 0;
	}
	html .page .inset-sm-left-10 {
		padding-left: 10px;
	}
	html .page .inset-sm-left-20 {
		padding-left: 20px;
	}
	html .page .inset-sm-left-30 {
		padding-left: 30px;
	}
	html .page .inset-sm-left-50 {
		padding-left: 50px;
	}
	html .page .inset-sm-left-80 {
		padding-left: 80px;
	}
	html .page .inset-sm-left-93 {
		padding-left: 93px;
	}
	html .page .inset-sm-left-100 {
		padding-left: 100px;
	}
	html .page .inset-sm-left-115 {
		padding-left: 115px;
	}
}

@media (min-width: 992px) {
	html .page .inset-md-left-0 {
		padding-left: 0;
	}
	html .page .inset-md-left-10 {
		padding-left: 10px;
	}
	html .page .inset-md-left-20 {
		padding-left: 20px;
	}
	html .page .inset-md-left-30 {
		padding-left: 30px;
	}
	html .page .inset-md-left-50 {
		padding-left: 50px;
	}
	html .page .inset-md-left-80 {
		padding-left: 80px;
	}
	html .page .inset-md-left-93 {
		padding-left: 93px;
	}
	html .page .inset-md-left-100 {
		padding-left: 100px;
	}
	html .page .inset-md-left-115 {
		padding-left: 115px;
	}
}

@media (min-width: 1200px) {
	html .page .inset-lg-left-0 {
		padding-left: 0;
	}
	html .page .inset-lg-left-10 {
		padding-left: 10px;
	}
	html .page .inset-lg-left-20 {
		padding-left: 20px;
	}
	html .page .inset-lg-left-30 {
		padding-left: 30px;
	}
	html .page .inset-lg-left-50 {
		padding-left: 50px;
	}
	html .page .inset-lg-left-80 {
		padding-left: 80px;
	}
	html .page .inset-lg-left-93 {
		padding-left: 93px;
	}
	html .page .inset-lg-left-100 {
		padding-left: 100px;
	}
	html .page .inset-lg-left-115 {
		padding-left: 115px;
	}
}

@media (min-width: 1800px) {
	html .page .inset-xl-left-0 {
		padding-left: 0;
	}
	html .page .inset-xl-left-10 {
		padding-left: 10px;
	}
	html .page .inset-xl-left-20 {
		padding-left: 20px;
	}
	html .page .inset-xl-left-30 {
		padding-left: 30px;
	}
	html .page .inset-xl-left-50 {
		padding-left: 50px;
	}
	html .page .inset-xl-left-80 {
		padding-left: 80px;
	}
	html .page .inset-xl-left-93 {
		padding-left: 93px;
	}
	html .page .inset-xl-left-100 {
		padding-left: 100px;
	}
	html .page .inset-xl-left-115 {
		padding-left: 115px;
	}
}

.inset-right-0 {
	padding-right: 0;
}

.inset-right-10 {
	padding-right: 10px;
}

.inset-right-20 {
	padding-right: 20px;
}

.inset-right-30 {
	padding-right: 30px;
}

.inset-right-50 {
	padding-right: 50px;
}

.inset-right-80 {
	padding-right: 80px;
}

.inset-right-93 {
	padding-right: 93px;
}

.inset-right-100 {
	padding-right: 100px;
}

.inset-right-115 {
	padding-right: 115px;
}

@media (min-width: 480px) {
	.inset-xs-right-0 {
		padding-right: 0;
	}
    .inset-xs-right-10 {
		padding-right: 10px;
	}
	.inset-xs-right-20 {
		padding-right: 20px;
	}
	.inset-xs-right-30 {
		padding-right: 30px;
	}
	.inset-xs-right-50 {
		padding-right: 50px;
	}
	.inset-xs-right-80 {
		padding-right: 80px;
	}
	.inset-xs-right-93 {
		padding-right: 93px;
	}
	.inset-xs-right-100 {
		padding-right: 100px;
	}
	.inset-xs-right-115 {
		padding-right: 115px;
	}
}

@media (min-width: 768px) {
	.inset-sm-right-0 {
		padding-right: 0;
	}
	.inset-sm-right-10 {
		padding-right: 10px;
	}
	.inset-sm-right-20 {
		padding-right: 20px;
	}
	.inset-sm-right-30 {
		padding-right: 30px;
	}
	.inset-sm-right-50 {
		padding-right: 50px;
	}
	.inset-sm-right-80 {
		padding-right: 80px;
	}
	.inset-sm-right-93 {
		padding-right: 93px;
	}
	.inset-sm-right-100 {
		padding-right: 100px;
	}
	.inset-sm-right-115 {
		padding-right: 115px;
	}
}

@media (min-width: 992px) {
	.inset-md-right-0 {
		padding-right: 0;
	}
	.inset-md-right-10 {
		padding-right: 10px;
	}
	html .page .inset-md-right-20 {
		padding-right: 20px;
	}
	html .page .inset-md-right-30 {
		padding-right: 30px;
	}
	html .page .inset-md-right-50 {
		padding-right: 50px;
	}
	html .page .inset-md-right-80 {
		padding-right: 80px;
	}
	html .page .inset-md-right-93 {
		padding-right: 93px;
	}
	html .page .inset-md-right-100 {
		padding-right: 100px;
	}
	html .page .inset-md-right-115 {
		padding-right: 115px;
	}
}

@media (min-width: 1200px) {
	html .page .inset-lg-right-0 {
		padding-right: 0;
	}
	html .page .inset-lg-right-10 {
		padding-right: 10px;
	}
	html .page .inset-lg-right-20 {
		padding-right: 20px;
	}
	html .page .inset-lg-right-30 {
		padding-right: 30px;
	}
	html .page .inset-lg-right-50 {
		padding-right: 50px;
	}
	html .page .inset-lg-right-80 {
		padding-right: 80px;
	}
	html .page .inset-lg-right-93 {
		padding-right: 93px;
	}
	html .page .inset-lg-right-100 {
		padding-right: 100px;
	}
	html .page .inset-lg-right-115 {
		padding-right: 115px;
	}
}

@media (min-width: 1800px) {
	html .page .inset-xl-right-0 {
		padding-right: 0;
	}
	html .page .inset-xl-right-10 {
		padding-right: 10px;
	}
	html .page .inset-xl-right-20 {
		padding-right: 20px;
	}
	html .page .inset-xl-right-30 {
		padding-right: 30px;
	}
	html .page .inset-xl-right-50 {
		padding-right: 50px;
	}
	html .page .inset-xl-right-80 {
		padding-right: 80px;
	}
	html .page .inset-xl-right-93 {
		padding-right: 93px;
	}
	html .page .inset-xl-right-100 {
		padding-right: 100px;
	}
	html .page .inset-xl-right-115 {
		padding-right: 115px;
	}
}

.inset-left-7p {
	padding-left: 7%;
}

.inset-right-7p {
	padding-right: 7%;
}

.inset-left-11p {
	padding-left: 11%;
}

.inset-right-11p {
	padding-right: 11%;
}

.offset-top-0 {
	margin-top: 0;
}

.offset-top-4 {
	margin-top: 4px;
}

.offset-top-10 {
	margin-top: 10px;
}

.offset-top-14 {
	margin-top: 14px;
}

.offset-top-20 {
	margin-top: 20px;
}

.offset-top-24 {
	margin-top: 24px;
}

.offset-top-30 {
	margin-top: 30px;
}

.offset-top-34 {
	margin-top: 34px;
}

.offset-top-41 {
	margin-top: 41px;
}

.offset-top-50 {
	margin-top: 50px;
}

.offset-top-minus-80 {
    margin-top: -80px;
}

.offset-top-minus-90 {
    margin-top: -90px;
}

.offset-top-66 {
	margin-top: 66px;
}



.offset-top-98 {
	margin-top: 98px;
}

.offset-top-110 {
	margin-top: 110px;
}

.offset-top-124 {
	margin-top: 124px;
}

@media (min-width: 480px) {
	html .page .offset-xs-top-0 {
		margin-top: 0;
	}
	html .page .offset-xs-top-4 {
		margin-top: 4px;
	}
	html .page .offset-xs-top-10 {
		margin-top: 10px;
	}
	html .page .offset-xs-top-14 {
		margin-top: 14px;
	}
	html .page .offset-xs-top-20 {
		margin-top: 20px;
	}
	html .page .offset-xs-top-24 {
		margin-top: 24px;
	}
	html .page .offset-xs-top-30 {
		margin-top: 30px;
	}
	html .page .offset-xs-top-34 {
		margin-top: 34px;
	}
	html .page .offset-xs-top-41 {
		margin-top: 41px;
	}
	html .page .offset-xs-top-50 {
		margin-top: 50px;
	}
	html .page .offset-xs-top-66 {
		margin-top: 66px;
	}
	html .page .offset-xs-top-98 {
		margin-top: 98px;
	}
	html .page .offset-xs-top-110 {
		margin-top: 110px;
	}
	html .page .offset-xs-top-124 {
		margin-top: 124px;
	}
}

@media (min-width: 768px) {
	html .page .offset-sm-top-0 {
		margin-top: 0;
	}
	html .page .offset-sm-top-4 {
		margin-top: 4px;
	}
	html .page .offset-sm-top-10 {
		margin-top: 10px;
	}
	html .page .offset-sm-top-14 {
		margin-top: 14px;
	}
	html .page .offset-sm-top-20 {
		margin-top: 20px;
	}
	html .page .offset-sm-top-24 {
		margin-top: 24px;
	}
	html .page .offset-sm-top-30 {
		margin-top: 30px;
	}
	html .page .offset-sm-top-34 {
		margin-top: 34px;
	}
	html .page .offset-sm-top-41 {
		margin-top: 41px;
	}
	html .page .offset-sm-top-50 {
		margin-top: 50px;
	}
	html .page .offset-sm-top-66 {
		margin-top: 66px;
	}
	html .page .offset-sm-top-98 {
		margin-top: 98px;
	}
	html .page .offset-sm-top-110 {
		margin-top: 110px;
	}
	html .page .offset-sm-top-124 {
		margin-top: 124px;
	}
}

@media (min-width: 992px) {
	html .page .offset-md-top-0 {
		margin-top: 0;
	}
	html .page .offset-md-top-4 {
		margin-top: 4px;
	}
	html .page .offset-md-top-10 {
		margin-top: 10px;
	}
	html .page .offset-md-top-14 {
		margin-top: 14px;
	}
	html .page .offset-md-top-20 {
		margin-top: 20px;
	}
	html .page .offset-md-top-24 {
		margin-top: 24px;
	}
	html .page .offset-md-top-30 {
		margin-top: 30px;
	}
	html .page .offset-md-top-34 {
		margin-top: 34px;
	}
	html .page .offset-md-top-41 {
		margin-top: 41px;
	}
	html .page .offset-md-top-50 {
		margin-top: 50px;
	}
	html .page .offset-md-top-66 {
		margin-top: 66px;
	}
	html .page .offset-md-top-98 {
		margin-top: 98px;
	}
	html .page .offset-md-top-110 {
		margin-top: 110px;
	}
	html .page .offset-md-top-124 {
		margin-top: 124px;
	}
}

@media (min-width: 1200px) {
	html .page .offset-lg-top-0 {
		margin-top: 0;
	}
	html .page .offset-lg-top-4 {
		margin-top: 4px;
	}
	html .page .offset-lg-top-10 {
		margin-top: 10px;
	}
	html .page .offset-lg-top-14 {
		margin-top: 14px;
	}
	html .page .offset-lg-top-20 {
		margin-top: 20px;
	}
	html .page .offset-lg-top-24 {
		margin-top: 24px;
	}
	html .page .offset-lg-top-30 {
		margin-top: 30px;
	}
	html .page .offset-lg-top-34 {
		margin-top: 34px;
	}
	html .page .offset-lg-top-41 {
		margin-top: 41px;
	}
	html .page .offset-lg-top-50 {
		margin-top: 50px;
	}
	html .page .offset-lg-top-66 {
		margin-top: 66px;
	}
	html .page .offset-lg-top-98 {
		margin-top: 98px;
	}
	html .page .offset-lg-top-110 {
		margin-top: 110px;
	}
	html .page .offset-lg-top-124 {
		margin-top: 124px;
	}
}

@media (min-width: 1800px) {
	html .page .offset-xl-top-0 {
		margin-top: 0;
	}
	html .page .offset-xl-top-4 {
		margin-top: 4px;
	}
	html .page .offset-xl-top-10 {
		margin-top: 10px;
	}
	html .page .offset-xl-top-14 {
		margin-top: 14px;
	}
	html .page .offset-xl-top-20 {
		margin-top: 20px;
	}
	html .page .offset-xl-top-24 {
		margin-top: 24px;
	}
	html .page .offset-xl-top-30 {
		margin-top: 30px;
	}
	html .page .offset-xl-top-34 {
		margin-top: 34px;
	}
	html .page .offset-xl-top-41 {
		margin-top: 41px;
	}
	html .page .offset-xl-top-50 {
		margin-top: 50px;
	}
	html .page .offset-xl-top-66 {
		margin-top: 66px;
	}
	html .page .offset-xl-top-98 {
		margin-top: 98px;
	}
	.offset-xl-top-110 {
		margin-top: 110px;
	}
	.offset-xl-top-124 {
		margin-top: 124px;
	}
}

#telephone-header {
    background: transparent;
    box-shadow: unset;
}

.card-custom-transparent .Card-cardBackground-140 {
    box-shadow: unset;
    border-radius: unset;
} 

.card-custom-transparent .Card-cardBackground-140:after {
  background-color: transparent;
}

.btn-color-guel {
    background-color: #f5593d !important;
    border-color: #f5593d !important;
    color: #FFFFFF;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-color-guel:hover, .btncolor-guel:focus, .btn-color-guel:active, .btn-color-guel.active, .btn-color-guel:active:focus, .btn-color-guel:active:hover, .btn-color-guel.active:focus, .btn-color-guel:not(:disabled):not(.disabled):active:focus, .btn-color-guel:not(:disabled):not(.disabled):active, .btn-color-guel:not(:disabled):not(.disabled).active, .btn-color-guel:not(:disabled):not(.disabled).active:focus,
    .show > .btn-color-guel.dropdown-toggle {
      background-color: #f33816;
      color: #FFFFFF;
      border-color: #f33816;
      box-shadow: none; }
    .btn-color-guel .caret {
      border-top-color: #FFFFFF; }
    .btn-color-guel.disabled, .btn-color-guel.disabled:hover, .btn-color-guel.disabled:focus, .btn-color-guel.disabled.focus, .btn-color-guel.disabled:active, .btn-color-guel.disabled.active, .btn-color-guel:disabled, .btn-color-guel:disabled:hover, .btn-color-guel:disabled:focus, .btn-color-guel:disabled.focus, .btn-color-guel:disabled:active, .btn-color-guel:disabled.active, .btn-color-guel[disabled], .btn-color-guel[disabled]:hover, .btn-color-guel[disabled]:focus, .btn-color-guel[disabled].focus, .btn-color-guel[disabled]:active, .btn-color-guel[disabled].active,
    fieldset[disabled] .btn-color-guel,
    fieldset[disabled] .btn-color-guel:hover,
    fieldset[disabled] .btn-color-guel:focus,
    fieldset[disabled] .btn-color-guel.focus,
    fieldset[disabled] .btn-color-guel:active,
    fieldset[disabled] .btn-color-guel.active {
      background-color: #f33816;
      border-color: #f33816; }
    .btn-color-guel.btn-link {
      color: #f5593d; }
      .btn-color-guel.btn-link:hover, .btn-color-guel.btn-link:focus, .btn-color-guel.btn-link:active, .btn-color-guel.btn-link.active, .btn-color-guel.btn-link:focus:active,
      .open > .btn-color-guel.btn-link.dropdown-toggle {
        background-color: transparent !important;
        color: #f33816 !important; }
      .btn-color-guel.btn-link .caret {
        border-top-color: #f5593d; }
    .btn-color-guel .caret {
      border-top-color: #FFFFFF; }


.btn-color-transparent {
    background-color: transparent;
    border-color: transparent !important;
    color: #FFFFFF;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-color-transparent:hover, .btncolor-guel:focus, .btn-color-transparent:active, .btn-color-transparent.active, .btn-color-transparent:active:focus, .btn-color-transparent:active:hover, .btn-color-transparent.active:focus, .btn-color-transparent:not(:disabled):not(.disabled):active:focus, .btn-color-transparent:not(:disabled):not(.disabled):active, .btn-color-transparent:not(:disabled):not(.disabled).active, .btn-color-transparent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-color-transparent.dropdown-toggle {
      background-color: transparent;
      color: #FFFFFF;
      border-color: transparent;
      box-shadow: none; }
    .btn-color-transparent .caret {
      border-top-color: #FFFFFF; }
    .btn-color-transparent.disabled, .btn-color-transparent.disabled:hover, .btn-color-transparent.disabled:focus, .btn-color-transparent.disabled.focus, .btn-color-transparent.disabled:active, .btn-color-transparent.disabled.active, .btn-color-transparent:disabled, .btn-color-transparent:disabled:hover, .btn-color-transparent:disabled:focus, .btn-color-transparent:disabled.focus, .btn-color-transparent:disabled:active, .btn-color-transparent:disabled.active, .btn-color-transparent[disabled], .btn-color-transparent[disabled]:hover, .btn-color-transparent[disabled]:focus, .btn-color-transparent[disabled].focus, .btn-color-transparent[disabled]:active, .btn-color-transparent[disabled].active,
    fieldset[disabled] .btn-color-transparent,
    fieldset[disabled] .btn-color-transparent:hover,
    fieldset[disabled] .btn-color-transparent:focus,
    fieldset[disabled] .btn-color-transparent.focus,
    fieldset[disabled] .btn-color-transparent:active,
    fieldset[disabled] .btn-color-transparent.active {
      background-color: transparent;
      border-color: transparent; }
    .btn-color-transparent.btn-link {
      color: transparent; }
      .btn-color-transparent.btn-link:hover, .btn-color-transparent.btn-link:focus, .btn-color-transparent.btn-link:active, .btn-color-transparent.btn-link.active, .btn-color-transparent.btn-link:focus:active,
      .open > .btn-color-transparent.btn-link.dropdown-toggle {
        background-color: transparent !important;
        color: transparent !important; }
      .btn-color-transparent.btn-link .caret {
        border-top-color: transparent; }
    .btn-color-transparent .caret {
      border-top-color: #FFFFFF; }

      #lets-talk {
        /* box-shadow: 0 14px 26px -12px #f33816, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px #f33816; */
      }


#our-clients {
    margin-top: 65px;
}

.unit-align-center {
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.unit-align-left {
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.unit-align-right {
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.section-white-grey {
    background: #f2f2f2;
}

.custom-navbar {
    box-shadow: unset;
    padding: 20px;
}

.no-boxshadow {
    box-shadow: unset !important;
}

.react-tagsinput-input {
    display: none;
}

#notes-on-search {
    padding-right: 100px;
    margin-top: -30px;
}

.color-white {
    color: #FFFFFF;
}

/* tags style */

.input-tag {
    background: white;
    
    border-radius: 2px;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 5px 0;
  }
  
  .input-tag input {
    border: none;
    width: 100%;
  }
  
  .input-tag__tags {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  .input-tag__tags li {
    align-items: center;
    background: #0EB5CA;
    border-radius: 20px;
    color: white;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
  }
  
  .input-tag__tags li button {
    align-items: center;
    font-weight: bold;
    appearance: none;
    background: #FFFFFF;
    border: none;
    border-radius: 50%;
    color: black;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    height: 15px;
    justify-content: center;
    line-height: 0;
    margin-left: 8px;
    transform: rotate(45deg);
    width: 15px;
  }
  
  .input-tag__tags li.input-tag__tags__input {
    background: none;
    flex-grow: 1;
    padding: 0;
  }

  .page-header {
    background-color: transparent;
  }

  .radius-round {
    /* position: relative; */
display: block;
width: 32px;
height: 32px;
border-radius: 50%;
background-color: #5d6f7b;
color: #fff;
font-size: 18px;
font-weight: 700;
text-align: center;
line-height: 32px;
z-index: 2;
  }

  .padding-l-r-section {
      padding-left: 24px;
      padding-right: 24px;
  }

  #einloggen-wo-narrow::after {
      display: none;
      
  }

  #einloggen-wo-narrow {
    margin: 5px 3px ;
  }

  .headroom--not-top.headroom--pinned .btn-color-transparent {
      color: black;
  }
  .headroom--not-top.headroom--pinned .btn i {
    color: black !important;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: #6bd098 !important;
}

.reason-list {
    font-size: 16px;
    font-weight: 500;
}

h2 .style-Monat-onPricing:first-child {
    top: 0px;
    position: relative;
    font-size: 14px;
}

h2 .mwst-pricing {
    font-size: 40%;
}

#header-pricing-tipp {
    z-index: 999;
}

#tipp-card {
    margin-top: -70px;
}

#fixed-plugin-custom {
    position: fixed;
    right: 0;
    width: 134px;
    background: #51bcda;
    z-index: 1031;
    border-radius: 8px 0 0 8px;
    text-align: center;
    top: 120px;
    color: white;
    height: 80px;
    padding-top: 5px;
}

#fixed-plugin-create-job {
    color: white;
    text-decoration: unset;
}

.mark-highlight mark {
    border-bottom: #E6FC88 solid 0.1em;
    -webkit-box-shadow: inset 0 -0.4em 0 0 #E6FC88;
    box-shadow: inset 0 -0.4em 0 0 #E6FC88;
}

.small-custom-text-profile {
    font-size: 40%;
}

.card-stats .card-body .numbers .card-category b {
    color: black;
    font-size: 80%;
}

.MuiSvgIcon-root.big-logo {
    font-size: 5.5rem;
}

.right-line {
    border-right: 1px solid grey;
}

.page-header {
    background-color: transparent;
  }

  .category, .card-category {
    text-transform: unset;
  }
/* message */
/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

   
  .toggler-wrapper input[type="checkbox"] {
    display: none;
  }
  
  .toggler-wrapper input[type="checkbox"]:checked+.toggler-slider {
    background-color: #44cc66;
  }
  
  .toggler-wrapper .toggler-slider {
    background-color: #ccc;
    position: absolute;
    border-radius: 100px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  
  .toggler-wrapper .toggler-knob {
    position: absolute;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  
  
  /*--------------------------------------------------------------
  3.0 Effects Styles 
  --------------------------------------------------------------*/
  
  
  /*Style 1*/
  
  .toggler-wrapper.style-1 input[type="checkbox"]:checked+.toggler-slider .toggler-knob {
    left: calc(100% - 19px - 3px);
  }
  
  .toggler-wrapper.style-1 .toggler-knob {
    width: calc(25px - 6px);
    height: calc(25px - 6px);
    border-radius: 50%;
    left: 3px;
    top: 3px;
    background-color: #fff;
  }
  